<template>
  <v-main>
    <register-form></register-form>
  </v-main>
</template>

<script>
import RegisterForm from "@/components/RegisterForm.vue";
export default {
  components: {
    RegisterForm,
  },
};
</script>

<style scoped>
</style>