<template>
  <v-app-bar app height="72" class="d-flex justify-center">
    <v-toolbar-title>
      <img height="50px" src="/logo.png" alt="Edmonton Sound Map Logo" />
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
export default {};
</script>

<style scoped>
</style>