<template>
  <v-main>
    <connect-form></connect-form>
  </v-main>
</template>

<script>
import ConnectForm from "@/components/ConnectForm.vue";
export default {
  components: {
    ConnectForm,
  },
};
</script>

<style scoped>
</style>