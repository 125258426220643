import { render, staticRenderFns } from "./AdminPage.vue?vue&type=template&id=5e7a7546&scoped=true&"
import script from "./AdminPage.vue?vue&type=script&lang=js&"
export * from "./AdminPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e7a7546",
  null
  
)

export default component.exports